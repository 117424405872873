import { AdvertisementReward } from "~/components/advertisement/claim/ClaimWizardDialog/declarations";
import { DTOClaimRewardFragment } from "~/components/advertisement/graphql/claimRewardFragment.generated";
import { DTORewardType } from "~/declarations/graphql/types";
import assertUnreachable from "~/utils/assertUnreachable";

const mapDTOClaimRewardFragmentToAdvertisementReward = (
  dto?: DTOClaimRewardFragment | null
): AdvertisementReward | null => {
  if (!dto) {
    return null;
  }

  switch (dto.__typename) {
    case "TokenPostReward": {
      return {
        type: DTORewardType.Token,
        amount: dto.price.cleoValue
      };
    }
    case "NftPostReward": {
      return {
        type: DTORewardType.Nft,
        name: dto.nft.name
      };
    }
  }

  assertUnreachable(dto);
};

export default mapDTOClaimRewardFragmentToAdvertisementReward;
