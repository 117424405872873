import { AdvertisementReward } from "~/components/advertisement/claim/ClaimWizardDialog/declarations";
import { Currency } from "~/components/payments/constants/money";
import formatMoney from "~/components/payments/formatters/formatMoney";
import { DTORewardType } from "~/declarations/graphql/types";
import assertUnreachable from "~/utils/assertUnreachable";

export const getRewardTextDescription = (
  reward: AdvertisementReward | null | undefined
): string => {
  if (!reward) {
    return "";
  }

  switch (reward.type) {
    case DTORewardType.Nft:
      return reward.name;
    case DTORewardType.Token:
      return formatMoney({
        amount: reward.amount,
        currency: Currency.Cleo,
        currencySignAsPrefix: false
      });
  }

  assertUnreachable(reward);
};
