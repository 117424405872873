/* eslint-disable cleo/no-deep-imports */
import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../../../../declarations/graphql/types";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTOAdvertisementQuestionIdsQueryVariables = Types.Exact<{
  id: Types.Scalars["String"];
}>;

export type DTOAdvertisementQuestionIdsQuery = {
  __typename?: "Query";
  advertisement: {
    __typename?: "Advertisement";
    id: string;
    questions?: Array<{ __typename?: "Question"; id: string }> | null;
  };
};

export const AdvertisementQuestionIdsQueryDocument = gql`
  query AdvertisementQuestionIdsQuery($id: String!) {
    advertisement(id: $id) {
      id
      questions {
        id
      }
    }
  }
`;

export function useAdvertisementQuestionIdsQuery(
  options: Omit<
    Urql.UseQueryArgs<DTOAdvertisementQuestionIdsQueryVariables>,
    "query"
  >
) {
  return Urql.useQuery<
    DTOAdvertisementQuestionIdsQuery,
    DTOAdvertisementQuestionIdsQueryVariables
  >({ query: AdvertisementQuestionIdsQueryDocument, ...options });
}
