import { DTOPostReward, DTORewardType } from "~/declarations/graphql/types";
import assertUnreachable from "~/utils/assertUnreachable";

export default function mapDTOPostRewardTypenameToDTORewardType(
  typename: NonNullable<DTOPostReward["__typename"]>
): DTORewardType {
  switch (typename) {
    case "NftPostReward":
      return DTORewardType.Nft;
    case "TokenPostReward":
      return DTORewardType.Token;
  }

  assertUnreachable(typename);
}
