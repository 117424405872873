import { useClient } from "urql";

import {
  DTOMyBalanceQuery,
  DTOMyBalanceQueryVariables,
  MyBalanceQueryDocument
} from "./graphql/myBalanceQuery.generated";

type RefetchBalanceHandler = () => Promise<void>;

export default function useRefetchBalance(): RefetchBalanceHandler {
  const urqlClient = useClient();

  return async () => {
    await urqlClient
      .query<DTOMyBalanceQuery, DTOMyBalanceQueryVariables>(
        MyBalanceQueryDocument,
        {},
        {
          requestPolicy: "network-only"
        }
      )
      .toPromise();
  };
}
