/* eslint-disable cleo/no-deep-imports */
import gql from "graphql-tag";

import * as Types from "../declarations/graphql/types";

export type DTOMultiBalanceFragment = {
  __typename?: "MultiBalance";
  usd: number;
  cleo: number;
};

export const MultiBalanceFragmentDoc = gql`
  fragment MultiBalanceFragment on MultiBalance {
    usd
    cleo
  }
`;
