/* eslint-disable cleo/no-deep-imports */
import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../../../../declarations/graphql/types";
import {
  DTOMultiBalanceFragment,
  MultiBalanceFragmentDoc
} from "../../../../../graphql/multiBalanceFragment.generated";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTOMyBalanceQueryVariables = Types.Exact<{ [key: string]: never }>;

export type DTOMyBalanceQuery = {
  __typename?: "Query";
  me: {
    __typename?: "Person";
    id: string;
    multiBalance: { __typename?: "MultiBalance" } & DTOMultiBalanceFragment;
  };
};

export const MyBalanceQueryDocument = gql`
  query MyBalanceQuery {
    me {
      id
      multiBalance {
        ...MultiBalanceFragment
      }
    }
  }
  ${MultiBalanceFragmentDoc}
`;

export function useMyBalanceQuery(
  options?: Omit<Urql.UseQueryArgs<DTOMyBalanceQueryVariables>, "query">
) {
  return Urql.useQuery<DTOMyBalanceQuery, DTOMyBalanceQueryVariables>({
    query: MyBalanceQueryDocument,
    ...options
  });
}
