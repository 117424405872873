import { AdvertisementQuestionWithAnswer } from "~/components/advertisement/declarations";
import { DTOAnswerRequest } from "~/declarations/graphql/types";

export default function mapDTOPostAnswerWithQuestionsToAnswersRequest(
  questionsWithAnswers: AdvertisementQuestionWithAnswer[]
): DTOAnswerRequest[] | null {
  if (questionsWithAnswers.some(({ answer }) => answer === "")) {
    return null;
  }

  return questionsWithAnswers.map(({ question, answer }) => ({
    questionId: question,
    answerId: answer
  }));
}
