import { DTORewardType } from "~/declarations/graphql/types";

export enum ClaimSuccessDialogVariant {
  withBusinessName = "withBusinessName",
  regular = "regular",
  predefined = "predefined"
}

type ClaimSuccessDialogPayloadRelatedToVariant =
  | {
      variant: Exclude<
        ClaimSuccessDialogVariant,
        ClaimSuccessDialogVariant.withBusinessName
      >;
    }
  | {
      variant: ClaimSuccessDialogVariant.withBusinessName;
      ownerBusinessName: string;
    };

export type ClaimSuccessDialogPayload = {
  rewardType: DTORewardType;
} & ClaimSuccessDialogPayloadRelatedToVariant;
