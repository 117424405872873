import { DTOWaitingRewardsFragment } from "~/components/advertisement/claim/useClaimFlow/graphql/waitingRewardFragment.generated";
import { DTOMyWaitingRewardStatus } from "~/declarations/graphql/types";

const checkUserAllowedToSkipRequiredFields = (
  advertisementId: string,
  waitingRewards?: DTOWaitingRewardsFragment[] | null
): boolean =>
  waitingRewards?.some(
    ({ post, status }) =>
      advertisementId === post.id && status === DTOMyWaitingRewardStatus.Active
  ) ?? false;

export default checkUserAllowedToSkipRequiredFields;
