/* eslint-disable cleo/no-deep-imports */
import gql from "graphql-tag";

import * as Types from "../declarations/graphql/types";

export type DTOWaitingRewardsFragment = {
  __typename?: "MyWaitingReward";
  status: Types.DTOMyWaitingRewardStatus;
  post: { __typename?: "Advertisement"; id: string };
};

export const WaitingRewardsFragmentDoc = gql`
  fragment WaitingRewardsFragment on MyWaitingReward {
    post {
      id
    }
    status
  }
`;
