import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import { useClient } from "urql";

import {
  ClaimSuccessDialogPayload,
  ClaimSuccessDialogVariant
} from "~/components/advertisement/claim/ClaimSuccessDialog/declarations";
import { ClaimWizardDialogPayload } from "~/components/advertisement/claim/ClaimWizardDialog/declarations";
import {
  DTORequiredFieldsAndWaitingRewardsQuery,
  DTORequiredFieldsAndWaitingRewardsQueryVariables,
  RequiredFieldsAndWaitingRewardsQueryDocument
} from "~/components/advertisement/claim/graphql/requiredFieldsAndWaitingRewardsQuery.generated";
import {
  AdvertisementQuestionIdsQueryDocument,
  DTOAdvertisementQuestionIdsQuery,
  DTOAdvertisementQuestionIdsQueryVariables
} from "~/components/advertisement/claim/useClaimFlow/graphql/advertisementQuestionIdsQuery.generated";
import checkUserAllowedToSkipRequiredFields from "~/components/advertisement/claim/utils/checkUserAllowedToSkipRequiredFields";
import getEmptyRequiredFields from "~/components/advertisement/claim/utils/getEmptyRequiredFields";
import { AdvertisementQuestionWithAnswer } from "~/components/advertisement/declarations";
import mapDTOPostAnswerWithQuestionsToAnswersRequest from "~/components/advertisement/reward/adapters/mapDTOPostAnswerWithQuestionsToAnswersRequest";
import mapDTOPostRewardTypenameToDTORewardType from "~/components/advertisement/reward/adapters/mapDTOPostRewardTypenameToDTORewardType";
import useRefetchBalance from "~/components/payments/balance/useRefetchBalance";
import { useAuthenticationContext } from "~/components/providers/AuthenticationContextProvider/AuthenticationContext";
import { AuthenticationStatus } from "~/components/providers/AuthenticationContextProvider/declarations";
import { AppDialog } from "~/components/providers/DialogsProvider/constants";
import { useAppDialog } from "~/components/providers/DialogsProvider/useAppDialog";
import ROUTES from "~/constants/routes";
import { DTORewardType } from "~/declarations/graphql/types";
import { isNotNullable } from "~/utils/common";
import getGraphQLErrorStatus from "~/utils/graphql/errors/getGraphQLErrorStatus";
import useHandleGraphQLErrors from "~/utils/graphql/errors/useHandleGraphQLErrors";

import { useClaimRewardMutation } from "./graphql/claimRewardMutation.generated";

const PERSONAL_INFO_INCOMPLETE_ERROR =
  "post.exception.reward.professionalInfoIncomplete";

export type HasNeedToClaimCheck = () => Promise<boolean>;

type UseClaimFlowResult = {
  handleClaim: (
    advertisementId: string,
    hasNeedToClaim?: HasNeedToClaimCheck
  ) => Promise<void>;
  fetching: boolean;
};

export default function useClaimFlow(): UseClaimFlowResult {
  const { t } = useTranslation("advertisement");
  const router = useRouter();
  const { authenticationStatus } = useAuthenticationContext();

  const { open: openWizardDialog, close: closeClaimWizardDialog } =
    useAppDialog<ClaimWizardDialogPayload>(AppDialog.ClaimWizard);
  const { open: openSuccessDialog } = useAppDialog<ClaimSuccessDialogPayload>(
    AppDialog.ClaimSuccess
  );

  const urqlClient = useClient();
  const [{ fetching }, claimRewardMutation] = useClaimRewardMutation();
  const handleGraphQLError = useHandleGraphQLErrors();
  const refetchBalance = useRefetchBalance();

  const claimReward =
    (rewardAlreadyApproved: boolean, advertisementId: string) =>
    async (
      answers?: AdvertisementQuestionWithAnswer[] | null
    ): Promise<void> => {
      const preparedAnswers = answers
        ? mapDTOPostAnswerWithQuestionsToAnswersRequest(answers)
        : null;

      const { data, error } = await claimRewardMutation({
        advId: advertisementId,
        ...(preparedAnswers ? { answers: preparedAnswers } : {})
      });

      if (!data) {
        handleGraphQLError(t("claim_error-on-claiming-reward"));
        return;
      }

      if (error) {
        if (getGraphQLErrorStatus(error) === PERSONAL_INFO_INCOMPLETE_ERROR) {
          setTimeout(() => {
            checkRequiredAndClaim(advertisementId);
          }, 0);

          return;
        }

        handleGraphQLError(error);
        return;
      }

      const { owner, reward, surveyReward } = data.claimReward;

      closeClaimWizardDialog();

      if (isNotNullable(reward)) {
        const rewardType = mapDTOPostRewardTypenameToDTORewardType(
          reward.__typename
        );

        const bonusRewardType = surveyReward
          ? mapDTOPostRewardTypenameToDTORewardType(surveyReward.__typename)
          : null;

        openSuccessDialog(
          getClaimedSuccessPayload({
            rewardType,
            rewardAlreadyApproved,
            ownerBusinessName: owner.businessName
          })
        );

        if (
          (rewardType === DTORewardType.Token ||
            bonusRewardType === DTORewardType.Token) &&
          rewardAlreadyApproved
        ) {
          refetchBalance();
        }
      }
    };

  const checkCurrentUserNeedToFillRequiredFields = async (
    advertisementId: string
  ): Promise<{
    needToFillInRequiredFields: boolean;
    allowToSkipRequired: boolean;
  }> => {
    const { data, error } = await urqlClient
      .query<
        DTORequiredFieldsAndWaitingRewardsQuery,
        DTORequiredFieldsAndWaitingRewardsQueryVariables
      >(
        RequiredFieldsAndWaitingRewardsQueryDocument,
        {},
        {
          requestPolicy: "network-only"
        }
      )
      .toPromise();

    const meData = data?.me;

    if (!meData) {
      handleGraphQLError(t("claim_error-on-getting-required-fields"));

      return {
        needToFillInRequiredFields: false,
        allowToSkipRequired: false
      };
    }

    if (error) {
      handleGraphQLError(error);

      return {
        needToFillInRequiredFields: false,
        allowToSkipRequired: false
      };
    }

    const allowToSkipRequired = checkUserAllowedToSkipRequiredFields(
      advertisementId,
      meData.waitingRewards
    );
    const emptyRequiredFields = getEmptyRequiredFields(meData);

    const hasEmptyRequiredFields = emptyRequiredFields.length > 0;

    const needToFillInRequiredFields =
      hasEmptyRequiredFields && !allowToSkipRequired;

    return {
      needToFillInRequiredFields,
      allowToSkipRequired
    };
  };

  const checkAdvertisementHasQuestions = async (
    advertisementId: string
  ): Promise<boolean> => {
    const { data, error } = await urqlClient
      .query<
        DTOAdvertisementQuestionIdsQuery,
        DTOAdvertisementQuestionIdsQueryVariables
      >(
        AdvertisementQuestionIdsQueryDocument,
        {
          id: advertisementId
        },
        {
          requestPolicy: "network-only"
        }
      )
      .toPromise();

    if (!data) {
      handleGraphQLError(t("claim_error-on-getting-questions"));

      return false;
    }

    if (error) {
      handleGraphQLError(error);

      return false;
    }

    return (
      isNotNullable(data.advertisement.questions) &&
      data.advertisement.questions.length > 0
    );
  };

  const checkRequiredAndClaim = async (
    advertisementId: string
  ): Promise<void> => {
    const [
      { needToFillInRequiredFields, allowToSkipRequired },
      hasAdvertisementQuestions
    ] = await Promise.all([
      checkCurrentUserNeedToFillRequiredFields(advertisementId),
      checkAdvertisementHasQuestions(advertisementId)
    ]);

    const handleClaimReward = claimReward(allowToSkipRequired, advertisementId);

    if (needToFillInRequiredFields || hasAdvertisementQuestions) {
      openWizardDialog({
        advertisementId,
        onClaim: handleClaimReward
      });

      return;
    }

    handleClaimReward();
  };

  const redirectToLogin = (): void => {
    router.push(ROUTES.auth.login);
  };

  const handleClaim = async (
    advertisementId: string,
    hasNeedToClaim?: HasNeedToClaimCheck
  ): Promise<void> => {
    if (authenticationStatus === AuthenticationStatus.unauthenticated) {
      redirectToLogin();
      return;
    }

    if (hasNeedToClaim) {
      const needToClaim = await hasNeedToClaim();

      if (!needToClaim) {
        return;
      }
    }

    await checkRequiredAndClaim(advertisementId);
  };

  return {
    handleClaim,
    fetching
  };
}

type GetClaimedSuccessPayloadOptions = {
  rewardType: DTORewardType;
  ownerBusinessName?: string;
  rewardAlreadyApproved: boolean;
};

const getClaimedSuccessPayload = ({
  ownerBusinessName,
  rewardAlreadyApproved,
  rewardType
}: GetClaimedSuccessPayloadOptions): ClaimSuccessDialogPayload => {
  if (rewardAlreadyApproved) {
    return { variant: ClaimSuccessDialogVariant.predefined, rewardType };
  }

  if (ownerBusinessName) {
    return {
      variant: ClaimSuccessDialogVariant.withBusinessName,
      ownerBusinessName,
      rewardType
    };
  }

  return { variant: ClaimSuccessDialogVariant.regular, rewardType };
};
