import useTranslation from "next-translate/useTranslation";
import { useCallback, useMemo, useRef } from "react";

import useSnackbar from "~/components/providers/SnackbarProvider/useSnackbar";
import { CustomGraphQLError, CustomGraphQLErrors } from "~/declarations/errors";
import useHandleCommonGraphQLError from "~/utils/graphql/errors/useHandleCommonGraphQLError";

// return true if you want to prevent next handlers
export type GraphQLErrorHandler = (
  error: CustomGraphQLError | Error
) => boolean;

const COMMON_ERROR_TRANSLATION_KEY = "common:something-went-wrong";
type UseErrorSnackbarResult = (errors: CustomGraphQLErrors | Error) => void;

export default function useHandleGraphQLErrors(
  customErrorHandlers?: GraphQLErrorHandler[]
): UseErrorSnackbarResult {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const handleCommonError = useHandleCommonGraphQLError();
  const customErrorHandlersRef = useRef(customErrorHandlers);
  const errorHandlers = useMemo(
    () => [...(customErrorHandlersRef.current ?? []), handleCommonError],
    [handleCommonError]
  );

  return useCallback(
    (error: CustomGraphQLErrors | Error): void => {
      if (!(error as CustomGraphQLErrors)?.graphQLErrors?.length) {
        enqueueSnackbar(t(COMMON_ERROR_TRANSLATION_KEY), {
          key: COMMON_ERROR_TRANSLATION_KEY,
          variant: "error",
          preventDuplicate: true
        });
        return;
      }

      (error as CustomGraphQLErrors).graphQLErrors.forEach(error => {
        errorHandlers.some(handler => handler(error));
      });
    },
    [enqueueSnackbar, errorHandlers, t]
  );
}
