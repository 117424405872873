/* eslint-disable cleo/no-deep-imports */
import gql from "graphql-tag";

import * as Types from "../../../declarations/graphql/types";

export type DTOClaimRewardFragment_NftPostReward_ = {
  __typename: "NftPostReward";
  nft: { __typename?: "Nft"; id: number; name: string };
};

export type DTOClaimRewardFragment_TokenPostReward_ = {
  __typename: "TokenPostReward";
  price: { __typename?: "Money"; cleoValue: number };
};

export type DTOClaimRewardFragment =
  | DTOClaimRewardFragment_NftPostReward_
  | DTOClaimRewardFragment_TokenPostReward_;

export const ClaimRewardFragmentDoc = gql`
  fragment ClaimRewardFragment on PostReward {
    __typename
    ... on TokenPostReward {
      price {
        cleoValue
      }
    }
    ... on NftPostReward {
      nft {
        id
        name
      }
    }
  }
`;
