import { CombinedError } from "urql/core";

import { isEmpty } from "~/utils/common";

const getGraphQLErrorStatus = (error?: CombinedError | null): string | null => {
  if (error && !isEmpty(error.graphQLErrors)) {
    const errorMessageObject = error.graphQLErrors[0];

    return (errorMessageObject?.extensions?.status as string) ?? null;
  }

  return null;
};

export default getGraphQLErrorStatus;
