/* eslint-disable cleo/no-deep-imports */
import gql from "graphql-tag";

import * as Types from "../../../../declarations/graphql/types";

export type DTORequiredFieldsFragment = {
  __typename?: "Person";
  jobTitle: string;
  businessName: string;
};

export const RequiredFieldsFragmentDoc = gql`
  fragment RequiredFieldsFragment on Person {
    jobTitle
    businessName
  }
`;
