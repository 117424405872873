export enum MeRequiredField {
  jobTitle = "jobTitle",
  businessName = "businessName"
}

export const PERSON_FIELDS_MAX_LENGTH: Record<
  MeRequiredField | string,
  number
> = {
  name: 30,
  nickname: 20,
  jobTitle: 100,
  businessName: 100,
  location: 100,
  profileUrl: 1000
};
