import { useCallback } from "react";

import useSnackbar from "~/components/providers/SnackbarProvider/useSnackbar";
import { CustomGraphQLError } from "~/declarations/errors";
import { GraphQLErrorHandler } from "~/utils/graphql/errors/useHandleGraphQLErrors";

export default function useHandleCommonGraphQLError(): GraphQLErrorHandler {
  const { enqueueSnackbar } = useSnackbar();

  return useCallback(
    (error: CustomGraphQLError | Error): boolean => {
      const errorMessage = error.message;

      enqueueSnackbar(errorMessage, {
        key: errorMessage,
        variant: "error",
        preventDuplicate: true
      });

      return true;
    },
    [enqueueSnackbar]
  );
}
