/* eslint-disable cleo/no-deep-imports */
import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../../../declarations/graphql/types";
import {
  DTOWaitingRewardsFragment,
  WaitingRewardsFragmentDoc
} from "../../../../graphql/waitingRewardFragment.generated";
import {
  DTORequiredFieldsFragment,
  RequiredFieldsFragmentDoc
} from "./meRequiredFieldsFragment.generated";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTORequiredFieldsAndWaitingRewardsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type DTORequiredFieldsAndWaitingRewardsQuery = {
  __typename?: "Query";
  me: {
    __typename?: "Person";
    id: string;
    waitingRewards?: Array<
      { __typename?: "MyWaitingReward" } & DTOWaitingRewardsFragment
    > | null;
  } & DTORequiredFieldsFragment;
};

export const RequiredFieldsAndWaitingRewardsQueryDocument = gql`
  query RequiredFieldsAndWaitingRewardsQuery {
    me {
      id
      ...RequiredFieldsFragment
      waitingRewards {
        ...WaitingRewardsFragment
      }
    }
  }
  ${RequiredFieldsFragmentDoc}
  ${WaitingRewardsFragmentDoc}
`;

export function useRequiredFieldsAndWaitingRewardsQuery(
  options?: Omit<
    Urql.UseQueryArgs<DTORequiredFieldsAndWaitingRewardsQueryVariables>,
    "query"
  >
) {
  return Urql.useQuery<
    DTORequiredFieldsAndWaitingRewardsQuery,
    DTORequiredFieldsAndWaitingRewardsQueryVariables
  >({ query: RequiredFieldsAndWaitingRewardsQueryDocument, ...options });
}
