/* eslint-disable cleo/no-deep-imports */
import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../../../../declarations/graphql/types";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTOClaimRewardMutationVariables = Types.Exact<{
  advId: Types.Scalars["String"];
  answers?: Types.InputMaybe<
    Array<Types.DTOAnswerRequest> | Types.DTOAnswerRequest
  >;
}>;

export type DTOClaimRewardMutation = {
  __typename?: "Mutation";
  claimReward: {
    __typename?: "Advertisement";
    id: string;
    owner: { __typename?: "Person"; businessName: string };
    reward?:
      | { __typename: "NftPostReward" }
      | { __typename: "TokenPostReward" }
      | null;
    surveyReward?:
      | { __typename: "NftPostReward" }
      | { __typename: "TokenPostReward" }
      | null;
    myReward?: { __typename: "Reward" } | null;
  };
};

export const ClaimRewardMutationDocument = gql`
  mutation ClaimRewardMutation($advId: String!, $answers: [AnswerRequest!]) {
    claimReward(advId: $advId, answers: $answers) {
      id
      owner {
        businessName
      }
      reward {
        __typename
      }
      surveyReward {
        __typename
      }
      myReward {
        __typename
      }
    }
  }
`;

export function useClaimRewardMutation() {
  return Urql.useMutation<
    DTOClaimRewardMutation,
    DTOClaimRewardMutationVariables
  >(ClaimRewardMutationDocument);
}
